<template>
  <div class="app-container">
    <eHeader :query="query" :queryMerOptions="queryMerOptions" :queryChannelOptions="queryChannelOptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="merCode" label="所属商户"/>
      <el-table-column prop="serialNo" label="支付流水号"/>
      <el-table-column prop="merOrderNo" label="商户订单号"/>
      <el-table-column prop="merSerialNo" label="商户流水号"/>
      <el-table-column prop="busType" label="业务类型">
        <template slot-scope="scope">
          <span>{{ parseType(scope.row.busType) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="busAmount" label="支付金额"/>
      <el-table-column prop="payStatus" label="支付状态">
         <template slot-scope="scope">
          <span>{{ parseStatus(scope.row.payStatus) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payStatusRemark" label="支付状态说明"/>
      <el-table-column prop="channelName" label="支付渠道"/>
      <el-table-column prop="createTime" label="请求时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <viewdDetail v-if="checkPermission(['ADMIN','ORDER_ALL','ORDER_VIEW'])" :data="scope.row" :sup_this="sup_this" style="margin-top: 2px;margin-bottom: 2px"/>
          <operlist v-if="checkPermission(['ADMIN','ORDER_ALL','ORDER_RECORD'])"  :data="scope.row.serialNo" :sup_this="sup_this" style="margin-top: 2px;margin-bottom: 2px"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initPmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/pms/order/header'
import viewdDetail from '@/components/pms/order/view'
import operlist from '@/components/pms/order/operlist'
import { parseStatus,parseType } from '@/components/pms/order/utils/index'
import { queryAllMerchantInfos,queryAllChannelInfos } from '@/api/dataPms'
export default {
  components: { eHeader, viewdDetail,operlist },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      queryMerOptions:[],queryChannelOptions:[]
    }
  },
  created() {
    this.queryMerOption()
    this.queryChannelOption()
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    parseStatus,
    parseType,
    queryAllMerchantInfos,
    queryAllChannelInfos,
    beforeInit() {
      this.url = '/api/configure/order/queryOrderInfoByParams'
      const query = this.query
      const merCode = query.merCode
      const serialNo = query.serialNo
      const merOrderNo = query.merOrderNo
      const merSerialNo = query.merSerialNo
      const payStatus = query.payStatus
      const busType = query.busType
      const channelName = query.channelName
      const startRequestDate = parseTime(query.startRequestDate)
      const endRequestDate = parseTime(query.endRequestDate)
      this.formData = { P: this.page, pageSize: this.size }
      if (merCode !== '' && merCode !== null) { this.formData['merCode'] = merCode }
      if (serialNo !== '' && serialNo !== null) { this.formData['serialNo'] = serialNo }
      if (merOrderNo !== '' && merOrderNo !== null) { this.formData['merOrderNo'] = merOrderNo }
      if (merSerialNo !== '' && merSerialNo !== null) { this.formData['merSerialNo'] = merSerialNo }
      if (payStatus !== '' && payStatus !== null) { this.formData['payStatus'] = payStatus }
      if (busType !== '' && busType !== null) { this.formData['busType'] = busType }
      if (channelName !== '' && channelName !== null) { this.formData['channelName'] = channelName }
      if (startRequestDate !== '' && startRequestDate !== null) { this.formData['startRequestDate'] = startRequestDate }
      if (endRequestDate !== '' && endRequestDate !== null) { this.formData['endRequestDate'] = parseTime(endRequestDate) }
      this.params = {'content':JSON.stringify(this.formData)}
      return true
    },
    queryMerOption(){
     const params = {'content':JSON.stringify()}
     queryAllMerchantInfos(params).then(res => {
        this.queryMerOptions = res.value
      })
    },
    queryChannelOption(){
     const params = {'content':JSON.stringify()}
     queryAllChannelInfos(params).then(res => {
        this.queryChannelOptions = res.value
      })
    }
  }
}
</script>

<style scoped>

</style>
